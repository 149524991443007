module.exports = {
  selectLanguage: 'Välj språk',
  en: 'English',
  sv: 'Svenska',
  metaDescription: 'Welcome to Bed and Bike Öckerö',
  navRooms: 'Rooms',
  navFindUs: 'Getting here',
  navActivities: 'Things to do',
  navPackages: 'Packages',
  introTitle: 'Welcome to Bed & Bike Öckerö',
  introText:
    'Come stay in a century old villa in the beautiful northern archipelago of Gothenburg. Enjoy our convenient rooms, a delicious homemade breakfast and the calmness of the island. We also provide <strong>bikes</strong> for rent. We love to show you around.',
  accomodationTitle: 'Our rooms',
  amenitiesTitle: 'About our space',
  amenitiesPreamlbe:
    'We have 3 double rooms and a single room. You have access to the common room, pentry och WC/shower on the second floor in our sentry old villa.',
  amenitiesExtra:
    'An extra bed for kids costs 300kr/night. Parking your car for the entire stay costs 100kr.',
  guest: 'Guest',
  guests: 'Guests',
  room: 'Our rooms',
  'room1.title': 'Room 1',
  'room2.title': 'Room 2',
  'room3.title': 'Room 3',
  'room4.title': 'Room 4',
  'room1.text':
    'A larger room with balcony and a great view of the garden. An extra bed can be aranged.',
  'room2.text': 'A cosy room in blue with a wardrobe to store your gear.',
  'room3.text': 'A romantic room with a table and chairs.',
  'room4.text': 'Our single room',
  facilitiesTitle: 'Amenities',
  facilitiesByline: 'This is included in your stay',
  breakfast: 'Organic breakfast',
  laundry: 'Washing machine',
  linen: 'Bedlinen & towels',
  wc: 'Shower/WC',
  wifi: 'WIFI',
  storage: 'Locked storage for bikes',
  cleaning: 'Cleaning',
  contactTitle: 'Contact/booking',
  contactText:
    'For more info and bookings, email us at <a href="mailto:bedandbikeockero@gmail.com">bedandbikeockero@gmail.com</a>',
  mapTitle: 'Getting here',
  mapText: 'Sörgårdsvägen 33, 475 32 Öckerö',
  mapLink: 'Show on map',
  bookingBarTitle: 'Book your stay at',
  from: 'From',
  to: 'To',
  checkIn: 'Check in',
  checkOut: 'Check out',
  booking: 'Request to Book',
  message: 'Message',
  send: 'Send',
  email: 'Your e-mail',
  thanksTitle: 'Thank you for your email!',
  thanksNote: 'We will get back to you as soon as we can',
  errorEmail: 'Please prove your email',
  moreInfo:
    'Do you have any other questions? Send us an email to <a href="mailto:bedandbikeockero@gmail.com">bedandbikeockero@gmail.com</a>',
  alwaysIncluded: 'Always included',
  bikePackage: 'Bicycle package',
  hikePackage: 'Hike package',
  bikeIntro:
    'Öckeröarna breder ut sig som ett pärlband mot Västerhavet mellan Vinga och Marstrand, i Göteborgs norra skärgård. Var och en av de tio öarna har sin egen karaktär och som du med fördel upplever från cykelsadeln. För att hitta guldkornen har vi satt samman ett 3-dagars cykelpaket. Förutom cykling får du uppleva vandring, god mat, fina båtturer och öarnas spännande historia.',
};
