module.exports = {
  selectLanguage: 'Välj språk',
  en: 'English',
  sv: 'Svenska',
  metaDescription:
    'Välkommen till Bed and Bike Öckerö, en pärla i Göteborgs skärgård för aktiviteter och rekreation.',
  navRooms: 'Våra rum',
  navFindUs: 'Hitta hit',
  navActivities: 'Aktiviteter',
  navPackages: 'Cykelpaket',
  introTitle: 'Välkommen till Bed & Bike Öckerö',
  introText:
    'Välkommen till Bed & Bike Öckerö, en pärla för aktiviteter och rekreation. Hos oss kan du njuta av husets lugn, vackra rum och god frukost. Hos oss kan självklart hyra cykel. Vi kan erbjuda förslag på turer såväl med <strong>cykel</strong>, <strong>kajak</strong> som <strong>vandring</strong>. För att uppleva guldkornen har vi satt samman ett <a href="/sv/cykelpaket">3-dagars cykelpaket.</a>',
  accomodationTitle: 'Våra rum',
  amenitiesTitle: 'Så här bor du hos oss',
  amenitiesPreamlbe:
    'Vi har 3 dubbelrum och 1 enkelrum,  gemensamt allrum, pentry och WC/dusch på andra våning i ett över hundra år gammalt skärgårdshus.',
  amenitiesExtra:
    'Barn i extrasäng kostar 300kr/natt. Parkering för hela din vistelse kostar  100kr.',
  guest: 'gäst',
  guests: 'gäster',
  room: 'Våra rum',
  'room1.title': 'Rum 1',
  'room2.title': 'Rum 2',
  'room3.title': 'Rum 3',
  'room4.title': 'Rum 4',
  'room1.text':
    'Ett större rum med egen balkong och plats för en extrasäng vid behov.',
  'room2.text': 'Ett mysigt rum i blått med egen garderob.',
  'room3.text': 'Ett romantiskt rum med bord och stolar.',
  'room4.text': 'Singelrummet intimt med pärlspont och snedtak.',
  facilitiesTitle: 'Faciliteter',
  facilitiesByline: 'Allt detta ingår i priset',
  breakfast: 'Ekologisk frukost',
  laundry: 'Tvättmaskin',
  linen: 'Lakan & handduk',
  wc: 'Dusch/WC',
  wifi: 'Trådlöst internet',
  storage: 'Cykelrum med lås',
  cleaning: 'Städning',
  contactTitle: 'Kontakt/Boka',
  contactText:
    'För mer info och bokningar, maila oss på <a href="mailto:bedandbikeockero@gmail.com">bedandbikeockero@gmail.com</a>',
  mapTitle: 'Hitta hit',
  mapText: 'Sörgårdsvägen 33, 475 32 Öckerö',
  mapLink: 'Visa på karta',
  bookingBarTitle: 'Boka din vistelse hos oss redan idag!',
  from: 'Från',
  to: 'Till',
  checkIn: 'Incheckning',
  checkOut: 'Utcheckning',
  booking: 'Bokningsförfrågan',
  message: 'Meddelande',
  send: 'Skicka',
  email: 'Din e-post',
  errorEmail: 'Var vänlig ange din epost',
  thanksTitle: 'Tack för din förfrågan!',
  thanksNote: 'Vi återkommer så snart vi kan',
  moreInfo:
    'Har du övriga frågor? Hör av dig till <a href="mailto:bedandbikeockero@gmail.com">bedandbikeockero@gmail.com</a>',
  alwaysIncluded: 'Ingår alltid',
  bikePackage: 'Cykelpaket',
  bikeIntro:
    'Öckeröarna breder ut sig som ett pärlband mot Västerhavet mellan Vinga och Marstrand, i Göteborgs norra skärgård. Var och en av de tio öarna har sin egen karaktär och som du med fördel upplever från cykelsadeln. För att hitta guldkornen har vi satt samman ett 3-dagars cykelpaket. Förutom cykling får du uppleva vandring, god mat, fina båtturer och öarnas spännande historia.',
  bikeCardTitle1: 'Cykelpaket - 1 dag',
  bikeCardText1:
    'Denna dag får du via cykel och båt uppleva några av våra vackra öar. På fiskemuseet kan du få reda på hur vi levt och lever våra liv här ute. Du äter en fantastisk sommarlunch på vår fiskrestaurang i Klåva. Under den guidade båtturen ut till Vinga får du lyssna till skepparens spännande historier.',
  bikeCardTitle2: 'Cykelpaket - 2 dagar',
  bikeCardText2:
    'Cyklingen i detta paket går första dagen till Rörö och dess naturreservat med betande hästar och får. Det består av ett stort klapperstensfält och ljunghedar. Övernattning sker på det gamla skärgårdshuset Bed and Bike Öckerö. Nästa dag går via Öckerö hamn till Björkö med båt. På slingrande vägar och stigar tar du dig norr ut på ön till Bovik, som varit äldsta fiskhamn och numera används som seglarläger för ungdom.',
  bikeCardTitle3: 'Cykelpaket - 3 dagar',
  bikeCardText3:
    'Varför inte uppleva fler av våra fantastiska öar och stanna ytterligare några dagar och få möjlighet att uppleva våra öars skiftande karaktärer. Du bor bekvämt i vårt vackra skärgårdshus. Efter den hemlagade frukosten går värden genom dagens rutt.',
  bike1Intro:
    'Välkommen till en dags ”ö-luffning" på några av Öckerös 10 öar. Du tar dig fram med cykel, båt och till fots.',
  hikePackage: 'Vandringspaket',
  hikeIntro:
    'Kom ut till oss för några dagars vandring i havsnära natur på Öckerööarna. Skärgårdsleden som är en del av Kuststigen sträcker sig från från Fotö i söder till Hälsö i norr. Våra tre nordöar stoltserar med ytterligare vacker vandring, Rörö med sina klapperstensfält, Hyppeln med mäktig västsida samt lummig ostsida med dungar av enar och en skyddad sandstrand. Vandringen på Källlö Knipplas västsida bjuder på en hänförande utsikt ut mot det mäktiga västerhavet.',
};
